import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const ChevronDown: FC<IconSvgProps> = ({ color, clss }) => {
    const styles = {
        svg: {
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
    };

    return (
        <svg style={{ ...styles.svg, transform: 'rotate(90deg)' }} className={clss} width="12" height="9" viewBox="-3 -1 12 9">
            <g fill="none" fillRule="evenodd">
                <g fill={color}>
                    <g>
                        <path d="M0 1.026L2.956 4 0 6.974 1.024 8 4.49 4.513 5 4 4.49 3.487 1.025 0z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ChevronDown;
